<template>
    <div class="card">
        <div class="card-body">
            <h1 class="card-title">
                <i class="mdi mdi-table"></i>
                Results Summary
            </h1>

            <p class="card-description">Summary of election results</p>

            <div class-="row">
                <div class="col-12">
                    <div class="table-responsive">
                        <table class="table table-striped dataTable no-footer">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Candidate</th>
                                    <th>Party</th>
                                    <th>Votes</th>
                                    <th>%</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="(candidate, index) in Object.keys(votes)" :key="index">
                                    <td><img class="img img-responsive" :src="votes[candidate].photo || placeholder"></td>
                                    <td>{{ votes[candidate].name }}</td>
                                    <td>{{ votes[candidate].party }}</td>
                                    <td><b>{{ Number(votes[candidate].votes).toLocaleString() }}</b></td>
                                    <td>{{ getPercentage(votes[candidate].votes) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['votes', 'totalVotes'],

    methods: {
        /**
         * Find percentage of candidate's votes
         */
        getPercentage(votes) {
            if (!this.totalVotes) {
                return 0;
            }

            return Number(votes/this.totalVotes * 100).toFixed(2);
        }
    }
}
</script>

<style scoped>
.card {
    height: 500px;
    overflow-y: auto;
}
</style>