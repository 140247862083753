<template>
    <div>
        <div class="row margin-top">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-xs-12 col-3">
                                        <div class="form-group">
                                            <label>Result Status</label>

                                            <div class="select2-wrapper">
                                                <select class="select-2 form-control"
                                                    name="result_status_id"
                                                    ref="status"
                                                    data-placeholder="Select result status">
\                                                    <option value="all">All</option>
                                                    <option value="1">Confirmed</option>
                                                    <option value="2">Unconfirmed</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xs-12 col-3">
                                        <div class="form-group">
                                            <label>Constituency</label>

                                            <div class="select2-wrapper">
                                                <select class="select-2 form-control"
                                                    name="constituency_id"
                                                    ref="constituency"
                                                    data-placeholder="Select A Constituency">
                                                    <option value="">Select a constituency</option>
                                                    <option v-for="(constituency, index) in constituencies"
                                                        :value="constituency.id"
                                                        :key="index">{{ constituency.name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xs-12 col-3">
                                        <div class="form-group">
                                            <label>Branch</label>

                                            <select
                                                id="branch"
                                                ref="branch"
                                                class="select-2 form-control"
                                                data-placeholder="Select A Branch"
                                                :disabled="!params.constituency_id">
                                                <option value="">Select A Branch</option>
                                                <option v-for="(branch, index) in branches"
                                                    :value="branch.id"
                                                    :key="index">{{ branch.name }}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-xs-12 col-3">
                                        <label>Election Type</label>

                                        <select
                                            id="election"
                                            ref="type"
                                            class="select-2 form-control"
                                            data-placeholder="Select Election Type"
                                            :disabled="!params.constituency_id">
                                            <option value="presidential">Presidential</option>
                                            <option value="parliamentary">Parliamentary</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row margin-top">
            <div class="col-6">
                <candidates-table :votes="results_summary.votes_summary"
                    v-if="results_summary && results_summary.votes_summary"
                    :totalVotes="results_summary.total_votes"></candidates-table>
            </div>

            <div class="col-6">
                <collation-progress :election_id="election_id"
                    :constituency_id="params.constituency_id">
                </collation-progress>
            </div>
        </div>

        <div class="row margin-top">
            <div class="col-6">
                <candidates-pie-chart :votes="results_summary.votes_summary"
                    v-if="results_summary && results_summary.votes_summary"
                    :totalVotes="results_summary.total_votes"></candidates-pie-chart>
            </div>

            <div class="col-6">
                <candidates-graph :votes="results_summary.votes_summary"
                    v-if="results_summary && results_summary.votes_summary"
                    :totalVotes="results_summary.total_votes"></candidates-graph>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import CandidatesTable from './CandidatesTable';
import CollationProgress from './CollationProgress';
import CandidatesPieChart from './CandidatesPieChart';
import CandidatesGraph from './CandidatesGraph';

export default {
    props: ['election_id'],

    computed: {
        ...mapGetters({
            branches: 'Branches/getAll',
            constituencies: 'Constituencies/getAll',
            results_summary: 'Reports/getSummaryData'
        }),

        /**
         * Get which entity whether branches or candidates we are reporting on
         *
         * @return String
         */
        entity() {
            if (this.params.branch_id) {
                return 'candidates';
            }

            if (this.params.constituency_id) {
                return 'branches';
            }

            return 'branches';
        }
    },

    components: {
        CandidatesTable,
        CollationProgress,
        CandidatesGraph,
        CandidatesPieChart
    },

    data() {
        return {
            params: {
                branch_id: "",
                constituency_id: "",
                status_id: "all",
                type: "presidential"
            }
        }
    },

    watch: {
        params: {
            deep: true,
            handler() {
                this.getResultsSummaryData();
            }
        }
    },

    methods: {
        ...mapActions({
            loadBranches: 'Branches/all',
            loadConstituencies: 'Constituencies/all',
            loadResultsSummary: 'Reports/loadSummary'
        }),

        /**
         * Load constituencies into store
         *
         * @return {Undefined}
         */
        getConstituencies() {
            this.loadConstituencies()
                .then(response => {
                    if (this.constituencies.length) {
                        $(this.$refs.constituency).val(this.constituencies[0].id).change();
                    }
                })
                .catch(errors => this.notify(this.buildErrors(errors), 'error'));
        },

        /**
         * Load branched into store
         *
         * @return {Undefined}
         */
        getBranches() {
            this.loadBranches()
                .catch(errors => this.notify(this.buildErrors(errors), 'error'));
        },

        /**
         * Load election results summary data into store
         *
         * @param {Object} query Query params
         * @return {Undefined}
         */
        getResultsSummaryData(query = {}) {
            query.type = this.params.type;

            if (this.params.branch_id) {
                query.branch_id = this.params.branch_id;
            }

            if (this.params.constituency_id) {
                query.constituency_id = this.params.constituency_id;
            }

            query.status_id = this.params.status_id;

            this.loadResultsSummary({id: this.election_id, query})
                .catch(errors => this.notify(this.buildErrors(errors), 'error'));

            clearTimeout(this.timeout);
            this.timeout = setTimeout(this.getResultsSummaryData, 60000);
        },

        /**
         * Handler for when constituency changes
         *
         * @param {Object} event Constituency change event
         * @return {Undefined}
         */
        constituencyChanged(event) {
            this.params.constituency_id = event.target.value;

            if (!event.target.value) {
                this.params.branch_id = "";
                $(this.$refs.branch).val('').change();
            }

            this.getBranches({constituency_id: event.target.value});
        },

        /**
         * Handler for when branch changes
         *
         * @param {Object} event Branch change event
         * @return {Undefined}
         */
        branchChanged(event) {
            this.params.branch_id = event.target.value;
        },

        /**
         * Handler for when result status changes
         *
         * @param {Object} event Status change event
         * @return {Undefined}
         */
        statusChanged(event) {
            this.params.status_id = event.target.value;
        },

        typeChanged(event) {
            this.params.type = event.target.value;
        },

        /**
         * Register event handlers for select 2 change events
         *
         * @return {Undefined}
         */
        registerEventListeners() {
            $(this.$refs.filter).on('change', this.filterChanged);
            $(this.$refs.constituency).on('change', this.constituencyChanged);
            $(this.$refs.branch).on('change', this.branchChanged);
            $(this.$refs.status).on('change', this.statusChanged);
            $(this.$refs.type).on('change', this.typeChanged);
        }
    },

    mounted() {
        this.getConstituencies();
        this.registerEventListeners();
        this.getResultsSummaryData();
    },

    beforeDestroy() {
        clearTimeout(this.timeout);
    }
}
</script>

<style scoped>
.margin-top {
    margin-top: 30px;
}
</style>