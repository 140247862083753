<template>
    <div class="card">
        <div class="card-body">
            <h1 class="card-title">
                <i class="mdi mdi-chart-pie"></i>
                Election Results Overview
            </h1>

            <p class="card-description">Results chart</p>

            <div class-="row">
                <div class="col-12">
                    <div id="results-chart">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['votes', 'totalVotes'],

    data() {
        return {
            dataPoints: []
        }
    },

    computed: {
        /**
         * Determine whether a graph can be plotted
         */
        canDrawChart() {
            let votes = [];
            let vm = this;

            Object.keys(vm.votes).forEach(function(id) {
                votes.push(vm.votes[id]);
            });

            return votes.some(function(vote) {
                return vote.votes != 0;
            })
        }
    },

    watch: {
        votes() {
            this.initiateChart();
        },

        totalVotes() {
            this.initiateChart();
        }
    },


    methods: {
        /**
         * Set up data to draw a chart
         */
        initiateChart() {
            let vm = this;
            vm.dataPoints = [];

            Object.keys(vm.votes).forEach(function(id) {
                let vote = vm.votes[id];

                vm.dataPoints.push({
                    y: Number(vote.votes / vm.totalVotes * 100).toFixed(2),
                    name: vote.name
                });

                if (vm.dataPoints.length) {
                    vm.dataPoints[0].exploded = true;
                }
            });

            if (this.dataPoints.length) {
                if (!this.canDrawChart) {
                    this.hideChart();
                } else {
                    this.drawChart();
                }
            } else {
                this.hideChart();
            }
        },

        drawChart() {
            CanvasJS.addColorSet("chartColors",
                [
                    '#FF6347',
                    '#FFA500',
                    '#32CD32',
                    '#00CED1',
                    '#4169E1'
                ]
            );

            var chart = new CanvasJS.Chart("results-chart", {
                colorSet: "chartColors",
                exportEnabled: true,
                animationEnabled: true,
                theme: "light2",
                legend:{
                    cursor: "pointer",
                    verticalAlign: "bottom",
                    horizontalAlign: "center"
                },
                data: [{
                    type: "pie",
                    showInLegend: true,
                    toolTipContent: "{name}: <strong>{y}</strong>",
                    indexLabel: "{name}",
                    dataPoints: this.dataPoints,
                }]
            });

            chart.render();
        },

        /**
         * Display a message instead of drawing a blank graph when there are no results
         */
        hideChart() {
            $('#results-chart').html('<p class="text-center lead">No results available</p>');
        }
    },

    mounted() {
        this.initiateChart();
    }
}
</script>

<style scoped>
#results-chart {
    height: 500px;
}
</style>