<template>
    <div class="card">
        <div class="card-body">
            <h1 class="card-title">
                <i class="mdi mdi-chart-bar"></i>
                Election Results Overview
            </h1>

            <p class="card-description">Results chart</p>

            <div class-="row">
                <div class="col-12">
                    <div id="results-graph">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['votes', 'totalVotes'],

    data() {
        return {
            dataPoints: []
        }
    },

    watch: {
        votes() {
            this.initiateGraph();
        },

        totalVotes() {
            this.initiateGraph();
        }
    },

    computed: {
        /**
         * Determine whether a graph can be plotted
         */
        canDrawGraph() {
            let votes = [];
            let vm = this;

            Object.keys(vm.votes).forEach(function(id) {
                votes.push(vm.votes[id]);
            });

            return votes.some(function(vote) {
                return vote.votes != 0;
            })
        }
    },

    methods: {
        initiateGraph() {
            this.dataPoints = [];
            let vm = this;

            Object.keys(vm.votes).forEach(function(id) {
                let vote = vm.votes[id];

                vm.dataPoints.push({
                    y: Number(vote.votes),
                    label: vote.name
                });
            });

            if (this.dataPoints.length) {
                if (!this.canDrawGraph) {
                    this.hideGraph();
                } else {
                    this.drawGraph();
                }
            } else {
                this.hideGraph();
            }
        },

        drawGraph() {
            let dataPoints = this.dataPoints;

            CanvasJS.addColorSet("graphColors",
                [
                    '#FF6347',
                    '#FFA500',
                    '#32CD32',
                    '#00CED1',
                    '#4169E1'
                ]);

            var chart = new CanvasJS.Chart("results-graph", {
                colorSet: "graphColors",
                exportEnabled: true,
                animationEnabled: true,
                theme: "light1",
                dataPointMaxWidth: 80,
                axisY :{
                    lineColor: "#3CB371",
                    gridColor: "#F0FFFF",
                },
                axisX :{
                    lineColor: "#3CB371",
                    gridColor: "#F0FFFF",
                },
                data: [
                    {
                        type: "column",
                        dataPoints
                    }
                ]
            });

            chart.render();
        },

        /**
         * Display a message instead of drawing a blank graph when there are no results
         */
        hideGraph() {
            $('#results-graph').html('<p class="text-center lead">No results available</p>');
        }
    },

    mounted() {
        this.initiateGraph();
    }
}
</script>

<style scoped>
#results-graph {
    height: 500px;
}
</style>