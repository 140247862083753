<template>
    <div class="card">
        <div class="card-body">
            <h1 class="card-title">
                <i class="mdi mdi-progress-clock"></i>
                Collation Progress
            </h1>

            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <select class="form-control" v-model="entity">
                            <option value="branches">Branches collation progress</option>
                            <option value="votes">Votes Progress</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class-="row">
                <div class="col-12">
                    <div id="collation-graph" class="mt-2">
                        <p class="lead text-center">
                            {{ entity | capitalize }} Completed (<span class="gauge-text">0</span>%)
                        </p>

                        <canvas class="mt-5" style="margin-left: 30%"></canvas>

                        <p class="text-center mt-5" v-show="entity == 'votes'">
                            {{ collation.collated_presidential }} out of {{ collation.total / 2 }} votes for presidential candidates
                        </p>

                        <p class="text-center" v-show="entity == 'votes'">
                            {{ collation.collated_parliamentary }} out of {{ collation.total / 2 }} votes for parliamentary candidates
                        </p>

                        <p class="text-center mt-5" v-show="entity == 'branches'">
                            {{ collation.collated }} out of {{ collation.total }} {{ entity }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    props: {
        constituency_id: {
            type: String,
            default: null
        },

        election_id: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            collation: {
                total: 0,
                collated: 0,
                collated_presidential: 0,
                collated_parliamentary: 0
            },
            entity: 'branches'
        }
    },

    watch: {
        collation: {
            deep: true,
            handler() {
                try {
                    this.drawGauge();
                } catch (error) {
                }
            }
        },

        constituency_id() {
            if (this.constituency_id) {
                this.loadCollationProgress();
            }
        },

        entity() {
            if (this.constituency_id) {
                this.loadCollationProgress();
            }
        }
    },

    methods: {
        ...mapActions({
            getCollationProgress: 'Reports/getCollation'
        }),

        drawGauge() {
            let percentageCompleted;

            if (this.collation.total && this.collation.collated) {
                percentageCompleted =  Number((this.collation.collated / this.collation.total) * 100);
            } else {
                percentageCompleted = 0.1;
            }

            let gauge;
            let target;

            let opts = {
                lines: 24,
                angle: 0,
                lineWidth: 0.1,
                pointer: {
                    length: 0.8,
                    strokeWidth: 0.02,
                    color: '#BA55D3'
                },
                limitMax: true,
                strokeColor: '#F0F3F3',
                percentColors: [[0.0, "#87CEFA" ], [0.50, "#87CEEB"], [1.0, "#26B99A"]],
                generateGradient: true,
                highDpiSupport: true
            };


            target = $('#collation-graph').find('canvas')[0];
            gauge = new Gauge(target).setOptions(opts);

            if (percentageCompleted <= 0) {
                percentageCompleted = 0.1;
            } else if (percentageCompleted > 100) {
                percentageCompleted = 100;
            }

            gauge.maxValue = 100;
            gauge.animationSpeed = 32;
            gauge.set(percentageCompleted);
            gauge.setTextField($('#collation-graph').find('.gauge-text')[0]);
        },

        loadCollationProgress() {
            this.getCollationProgress({
                id: this.election_id,
                query: {
                    collation_type: this.entity,
                    constituency_id: this.constituency_id
                }
            })
            .then(response => {
                this.collation = response;

                clearTimeout(this.timeout);
                this.timeout = setTimeout(this.loadCollationProgress, 50000);
            })
            .catch (errors => this.notify(this.buildErrors(errors), 'error'));
        }
    },

    beforeDestroy() {
        clearTimeout(this.timeout);
    }
}
</script>

<style scoped>
.card {
    height: 550px;
}
</style>